// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/logging/index.ts"
);
import.meta.hot.lastModified = "1711401652116.5322";
}
// REMIX HMR END

import { useMemo } from 'react'

import { type POJO } from '@/types'

import { devConsole } from './devConsole'
import { logger } from './log.client'
import { log } from './log.server'

const isServer = typeof document === 'undefined'
const info = isServer ? log.info : logger.info
const warning = isServer ? log.warn : logger.warning
const error = isServer ? log.error : logger.error

export function useLogger(context: POJO = {}) {
  return useMemo(() => {
    const onServer = typeof document === 'undefined'
    return {
      /** This will only log in "dev" environments or on the server */
      console: devConsole,
      info: (message: string, additionalData: POJO = {}) => {
        const localMessage = `${message}${onServer ? ': %o' : ''}`
        return info(localMessage, { ...context, ...additionalData })
      },
      warning: (message: string, additionalData: POJO = {}) => {
        const localMessage = `${message}${onServer ? ': %o' : ''}`
        return warning(localMessage, { ...context, ...additionalData })
      },
      error: (
        message: string,
        err: unknown = null,
        additionalData: POJO = {},
      ) => {
        const localMessage = `${message}${
          onServer ? ': error: %o \n data: %o' : ''
        }`
        return error(localMessage, err, { ...context, ...additionalData })
      },
    }
  }, [context])
}
